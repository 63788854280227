import * as bootstrap from 'bootstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import Quill from 'quill'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import TicketEntity from "../entity";
import TicketShowServices from "./show/services";
import TicketShowReports from "./show/reports";
import TicketShowDocuments from './show/documents';
import TicketShowOrders from './show/orders';
import TicketShowLogs from "./show/logs";
import TicketShowChildren from "./show/children";

export default class TicketOverviewShow extends DefaultController {
    private chatHistoryBody: HTMLElement | undefined;
    private commentForm: HTMLFormElement | undefined;
    private ticketCommentImageModal: any;
    private chatScrollBar: PerfectScrollbar | undefined;
    private commentEditor: any;

    async init() {
        this.entity = "tickets";
    
        this.ticketCommentImageModal = new bootstrap.Modal((document.querySelector("#ticketCommentImgModal") as HTMLElement));
        this.commentForm = document.querySelector("#ticket_comment_form") as HTMLFormElement
        this.chatHistoryBody = document.querySelector('.chat-history-body') as HTMLElement

        this.addChilds();
        await super.init();
        this.setupScrollbars();
        //@ts-ignore
        jQuery("#ticket_customer_id").on("select2:select", (e: any) => {
            //@ts-ignore
            jQuery("#ticket_customer_location_id").val(null).trigger("change");
            //@ts-ignore
            jQuery("#ticket_customer_contact_id").val(null).trigger("change");
        })
        jQuery("#ticket_status_id").on("select2:select", async (e: any) => {
            await this.save()
        })
    }

    addChilds() {
        this.childs = {
            services: new TicketShowServices(this),
            reports: new TicketShowReports(this),
            documents: new TicketShowDocuments(this),
            orders: new TicketShowOrders(this),
            logs: new TicketShowLogs(this),
            children: new TicketShowChildren(this)
        }
    }



    onSelectFilterResults(data: any, entity: any, element: any) {
        if (entity === "statuses") {
            return data.filter((d: { uuid: any; }) => d.uuid === this.data.status.uuid)[0].transitions
        }
        return data;
    }
    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("id") === "ticket_parent_id") {
            return {
                parent_id: this.data.uuid
            }
        } else if (elem.getAttribute("data-entity") !== "customers") {
            return {
                customer_id: (document.querySelector("#ticket_customer_id") as HTMLSelectElement).value
            }
        } else {
            return {}
        }
    }

    protected getEntityData(elem: any) {
        return TicketEntity.getEntityData(elem)
    }

    bindListeners() {
        if (document.querySelector("#ticket_save")) {
            (document.querySelector("#ticket_save") as HTMLButtonElement).addEventListener("click", async (e: any) => {
                e.preventDefault();
                await this.save();
            });
        }

        if (this.commentForm) {
            Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
            this.commentEditor = new Quill('#ticket_comment_desc', {
                bounds: '#ticket_comment_desc',
                placeholder: Utils.translate("ticket.chat.placeholder"),
                modules: {
                    toolbar: '#snow-toolbar',
                    imageDropAndPaste: {
                        handler: (imageDataUrl: any, type: any, imageData: any) => {
                            this.onDropItem(imageDataUrl, type, imageData)
                        }
                    }
                },
                theme: 'snow'
            });

            /*(document.querySelector("#ticket_comment_attachments") as HTMLInputElement).addEventListener("change", async (e) => {
                console.log("attachment")
                e.preventDefault();
                await this.submit();
            });*/
            (document.querySelector("#ticket_comment_save") as HTMLButtonElement).addEventListener("click", async (e) => {
                console.log("AAAAAA")
                e.preventDefault();
                await this.submit();
            });

            this.commentForm.addEventListener("submit", async (e) => {
                console.log("submit")
                e.preventDefault();
                await this.submit();
            });
            document.addEventListener("click", async (e) => {
                const target = e.target as HTMLElement;
                if (target.classList.contains("ticket_comment_img")) {
                    e.preventDefault();
                    const src = target.getAttribute("src") as string;
                    const modalElement = (document.querySelector("#ticketCommentImgModal") as HTMLElement);
                    (modalElement.querySelector("img") as HTMLImageElement).setAttribute("src", src);
                    this.ticketCommentImageModal.show();
                }
            });
            (this.commentForm.querySelector("#ticket_comment_internal_switch") as HTMLElement).addEventListener("click", (e) => {
                e.preventDefault();
                if (this.commentForm) {
                    const btn = (this.commentForm.querySelector("#ticket_comment_internal_switch") as HTMLElement)
                    if (btn.classList.contains("btn-success")) {
                        btn.classList.remove("btn-success");
                        btn.classList.add("btn-warning");
                        btn.innerHTML = `<i class="ti ti-lock ti-sm cursor-pointer mx-1"></i>`;
                        (this.commentForm.querySelector("#ticket_comment_internal") as HTMLInputElement).value = "true"
                    } else {
                        btn.classList.add("btn-success");
                        btn.classList.remove("btn-warning");
                        btn.innerHTML = `<i class="ti ti-lock-open ti-sm cursor-pointer mx-1"></i>`;
                        (this.commentForm.querySelector("#ticket_comment_internal") as HTMLInputElement).value = "false"
                    }
                }
            });
            (this.commentForm.querySelector("#ticket_comment_attachments_switch") as HTMLElement).addEventListener("click", (e) => {
                e.preventDefault();
                if (this.commentForm) {
                    (this.commentForm.querySelector("#ticket_comment_attachments") as HTMLElement).click();
                }
            });
        }
    }

    async onDropItem(imageDataUrl: any, type: any, imageData: any) {
        if (this.commentForm) {
        const internal = (this.commentForm.querySelector("#ticket_comment_internal") as HTMLInputElement).value

        const btn = (this.commentForm.querySelector("button.btn-primary")) as HTMLButtonElement;

        const fileNames = [imageData.name]
        const fileData = [imageDataUrl]
        btn.setAttribute("disabled", "disabled");
        const r = await Utils.entity.upsert({
            ticket_id: this.id,
            description: '',
            internal,
            files: fileData,
            file_names: fileNames
        }, 'ticket_comments')
        btn.removeAttribute("disabled");
            if (r.status === 200) {
                this.processChatAdd(r);
            } else {
                this.toastr.error(`${r.data.errors.map((e: any) => {
                    return e.message
                }).join("<br />")}`, `${Utils.translate('generic.failed')}`)
            }
        }
    }

    async submit() {
        if (this.commentForm) {
            const desc = this.commentEditor.root.innerHTML
            const internal = (this.commentForm.querySelector("#ticket_comment_internal") as HTMLInputElement).value
            const files = (document.querySelector("#ticket_comment_attachments") as HTMLInputElement).files
            const fileNames = []
            if (desc || (files && files.length > 0)) {
                const fileData = []
                if (files) {
                    for (let x = 0; x < files.length; x++) {
                        fileData.push(await Utils.getBase64(files[x]))
                        fileNames.push(files[x].name)
                    }
                }
                const btn = (this.commentForm.querySelector("button.btn-primary")) as HTMLButtonElement;
                btn.setAttribute("disabled", "disabled");
                const r = await Utils.entity.upsert({
                    ticket_id: this.id,
                    description: desc.length > 0 && desc !== '<p><br></p>' ? desc : '',
                    internal,
                    files: fileData,
                    file_names: fileNames
                }, 'ticket_comments')
                btn.removeAttribute("disabled");
                this.commentForm.reset();
                this.commentEditor.root.innerHTML = "";
                if (r.status === 200) {
                    this.processChatAdd(r);
                } else {
                    this.toastr.error(`${r.data.errors.map((e: any) => {
                        return e.message
                    }).join("<br />")}`, `${Utils.translate('generic.failed')}`)
                }
            }
        }
    }

    processChatAdd(r: any) {
        if (this.chatHistoryBody){
            const ul = this.chatHistoryBody.querySelector("ul") as HTMLElement
            r.data.forEach((comment: any) => {
                ul.innerHTML = ul.innerHTML + this.addComment(comment);
            })
            this.scrollToBottom()
        }
    }

    async save() {
        const elem = document.querySelector("div.app-ticket") as HTMLElement;
        const entityData = this.getEntityData(elem)
        console.log("entityData", entityData)
        await Utils.showLoader()
        const r = await Utils.entity.upsert(entityData, this.entity)
        await this.getEntity();
        await Utils.hideLoader()
    }


    addComment(comment: any) {
        let html = '';
        html += `<li class="chat-message ${comment.direction === "OUT" ? 'chat-message-right' : ''}">
                        <div class="d-flex overflow-hidden">
                          <div class="chat-message-wrapper flex-grow-1">
                            <div class="chat-message-text ${comment.internal ? 'internal' : ''}">
                              <p class="mb-0">`
        html += comment.description
        if (comment.attachments.length > 0) {
            const image_types = ["image/png", "image/jpeg", "image/svg+xml"]
            comment.attachments.forEach((attachment: any) => {
                const url = attachment.url;
                if (image_types.indexOf(attachment.type) !== -1) {
                    html += `<br /><img src="${url}" style="height: 120px;cursor: pointer;" class="img-thumbnail ticket_comment_img"/>`
                } else {
                    html += `${attachment.name}<br /><a href="${url}" target="_blank"><button class="btn btn-secondary"><i style="color:#FFF;" class="ti ti-download"></i>  Download</button> </a>`
                }
            })
        }
        html += `</p></div><div class="text-end text-muted mt-1">`
        if (comment.direction === "OUT") {
            html += `<i data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="${comment.internal ? 'Intern' : 'Extern'}" class="ti ti-checks ti-xs me-1 ${comment.internal ? 'ti-lock' : 'ti-lock-open'}"></i>`
        }
        html += `<small>${new Date(Date.parse(comment.created_at)).toLocaleString("de-DE", {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })} | ${comment.from}</small>
                            </div>
                          </div>
                        </div>
                      </li>`
        return html;
    }

    parseComments(data: any) {
        const ul = this.chatHistoryBody.querySelector("ul") as HTMLElement
        ul.innerHTML = "";
        data.comments.forEach((comment: any) => {
            ul.innerHTML = ul.innerHTML + this.addComment(comment);
        })
    }

    async updateEntity(data: any) {
        if (!this.chatHistoryBody) {
            return;
        }
        this.parseComments(data);
        this.scrollToBottom()
        this.setupTimer(data);

        jQuery("#ticket_queue_id").val("").trigger("change");
        data.ticket_queues.forEach((queue: any) => {
            const $newOption = jQuery("<option selected='selected'></option>").val(queue.uuid).text(queue.name)
            jQuery("#ticket_queue_id").append($newOption).trigger('change');
        })
        setInterval(() => {
            this.setupTimer(data);
        }, 1000);

        const orderBtn = document.querySelector("#ticket_overview_order_button") as HTMLButtonElement
        if (orderBtn) {
            if(data.customer) {
                orderBtn.removeAttribute("disabled")
                const customerId = data.customer.uuid;
                const customerLocationId = data.customer_location ? data.customer_location.uuid : null;
                let url = `/erp/orders/new?ticket_id=${data.uuid}`
                if (customerLocationId) {
                    url += `&customer_location_id=${customerLocationId}`
                }
                if (customerId) {
                    url += `&customer_id=${customerId}`
                }
                orderBtn.setAttribute("href", url);
            } else {
                orderBtn.setAttribute("disabled", "disabled")
            }
        }

        const childAddButton = document.querySelector("#ticket_show_add_children_button")
        if (childAddButton) {
            let url = `/${(window as any).currentLocale}/ticket/tasks/new?customer=${data.customer.uuid}|${data.customer.name}&parent=${data.uuid}|${data.title}`
            if (data.customer_location && data.customer_location.uuid) {
                url += `&customer_location=${data.customer_location.uuid}|${data.customer_location.name}`
            }
            if (data.customer_contact && data.customer_contact.uuid) {
                url += `&customer_contact=${data.customer_contact.uuid}|${data.customer_contact.name}`
            }
            if (data.assignee && data.assignee.uuid) {
                url += `&assignee=${data.assignee.uuid}|${data.assignee.name}`
            }
            if (data.priority && data.priority.uuid) {
                url += `&priority=${data.priority.uuid}|${data.priority.name}`
            }
            if (data.project && data.project.uuid) {
                url += `&project=${data.project.uuid}|${data.project.name}`
            }
            if (data.status && data.status.uuid) {
                url += `&status=${data.status.uuid}|${data.status.name}`
            }
            if (data.ticket_category && data.ticket_category.uuid) {
                url += `&category=${data.ticket_category.uuid}|${data.ticket_category.name}`
            }
            if (data.ticket_queues && data.ticket_queues.length > 0) {
                url += `&queue=${data.ticket_queues.map(q => `${q.uuid}|${q.name}`).join(",")}`
            }
            childAddButton.setAttribute("href", url)
        }
    }

    setupTimer(data: any) {
        const timerElem = document.querySelector("#ticket_footer_timer") as HTMLElement;
        const creationDate = new Date(Date.parse(data.created_at))
        const currentDate = new Date();
        //@ts-ignore
        const diff = currentDate - creationDate;
        timerElem.innerHTML = Utils.msToHMS(diff);
    }

    setupScrollbars() {
        // @ts-ignore
        this.chatScrollBar = new PerfectScrollbar(this.chatHistoryBody, {
            wheelPropagation: false,
            suppressScrollX: true,
            maxScrollbarLength: 200000
        });
        this.scrollToBottom()
    }

    scrollToBottom() {
        if (this.chatHistoryBody) {
            this.chatHistoryBody.scrollTop = this.chatHistoryBody.scrollHeight
        }
    }
}